<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <treeselectCode @treeDataChange="treeDataChange" :isDateShow="isDateShow" :placeholder="placeholderstar" style="width:210px" class="margin_right20"></treeselectCode>
                        <a-input v-model="form.phone" style="width:210px;" class="margin_right20" placeholder="请输入用户手机号" />
                        <a-range-picker @change="onChangeDate" v-model="form.dateTime" style="width: 210px;" class="margin_right20"/>
                        <a-button type="primary" class="margin_right20" @click="queryClick">查询</a-button>
                        <a-button type="primary" @click="resetClick">重置</a-button>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.phone"  :loading="tableLoading" :pagination="false"></a-table>
                </div>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
            </div>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {codeFn} from "@/utils/tools";
import {ChangeHistory} from "@/request/api/userManage";
import treeselectCode from "@/components/channel/treeselectCode"

export default {
    components: {MyPagination,treeselectCode},
    created() {
        if (codeFn("addCategory")) this.isShowAdd = true;
        if (codeFn("/admin/course/label/updLabel")) this.isShowUpd = true;
        if (codeFn("/admin/course/label/delLabel")) this.isShowDel = true;
    },
    data() {
        return {
            name:'',
            spinning: false, // 全局loading
            tableLoading: false,

            form:{
                afterAgentCode:'',
                phone:'',
                startTime:'',
                endTime:'',
                dateTime:[],
            },

            columns: [
                {
                    title: "用户姓名",
                    dataIndex: "nickName",
                    key: "nickName",
                },
                {
                    title: "用户手机",
                    dataIndex: "phone",
                    key: "phone",
                },
                {
                    title: "变更后渠道",
                    dataIndex: "afterAgentName",
                    key: "afterAgentName",
                },
                {
                    title: "变更时间",
                    dataIndex: "createTime",
                    key: "createTime",
                },
            ],
            tableList: [],

            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0, // 列表的总条数

            isDateShow:true,
            placeholderstar:'请选择变更来源',

            isShowAdd: false, // 修改是否隐藏
            isShowUpd: false, // 新增是否隐藏
            isShowDel: false, // 删除是否隐藏
        };
    },
    mounted() {
        this.getTagList();
    },
    methods: {
        // 搜索·变更前渠道
        treeDataChange(val){
            this.form.afterAgentCode = val
            this.isDateShow = true
        },

        // 搜索 - 修改时间
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.form.startTime = dateString[0];
                this.form.endTime = dateString[1];
            } else {
                this.form.startTime = "";
                this.form.endTime = "";
            }
        },

        // 点击搜索按钮
        queryClick(){
            this.pageNo = 1;
            this.pageSize = this.pageSize;
            this.getTagList();
        },

        // 点击重置按钮
        resetClick(){
            this.pageNo = 1;
            this.pageSize = this.pageSize;
            this.isDateShow = false
            this.form.afterAgentCode = ''
            this.form.phone = ''
            this.form.dateTime = []
            this.form.startTime = ''
            this.form.endTime = ''
            this.getTagList();
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },

        // 列表数据
        getTagList() {
            this.tableLoading = true;
            ChangeHistory({
                afterAgentCode:this.form.afterAgentCode,
                phone:this.form.phone,
                startTime:this.form.startTime,
                endTime:this.form.endTime,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },

        // 编辑
        onEdit(record) {
            this.$router.push(`/userManage/ownershipList/OwnershipDetails/${record.id}`);
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    display: flex;
    margin-bottom: 30px;
}

.avatar {
    width: 160px;
    height: 80px;
    object-fit: contain;
}

.btn-edit {
    margin-right: 10px;
}
.table-operator{
    display: flex;
}
/deep/ .ant-input{
    min-height: 36px !important;
}
/deep/ .ant-select-selection--single{
    min-height: 36px !important;
}
/deep/.ant-select-selection__rendered{
    line-height: 35px;
}
</style>
